//import embeddedMachineLearningForEdgeComputingImage from "../../components/ProgramCatalog/assets/embedded-machine-learning-for-edge-computing.webp";
import entcImage from "../../components/CoursePage/assets/entc-logo.webp";
//import unicImage from "../../components/CoursePage/assets/unic-logo.webp";
import uomImage from "../../components/CoursePage/assets/uom-logo.webp";
//import amayaDharmasiri from "../../components/CoursePage/assets/instructor-img/Amaya_Dharmasiri.webp";
//import ireshJayawardana from "../../components/CoursePage/assets/instructor-img/Iresh Jayawardana.webp";
//import kanchanaRanasinghe from "../../components/CoursePage/assets/instructor-img/Kanchana_Ranasinghe.webp";
//import sadeepJayasumana from "../../components/CoursePage/assets/instructor-img/Sadeep Jayasumana.webp";
import sahanHemachandra from "../../components/CoursePage/assets/instructor-img/Sahan Hemachandra.webp";
//import senuraMaduwantha from "../../components/CoursePage/assets/instructor-img/Senura Maduwantha.webp";
//import teharaFonseka from "../../components/CoursePage/assets/instructor-img/Tehara Fonseka.webp";
//import visalRajapakse from "../../components/CoursePage/assets/instructor-img/Visal Rajapakse.webp";
import kithminWickremasinghe from "../../components/CoursePage/assets/instructor-img/Kithmin.jpg";
import abarajithanGnaneswaran from "../../components/CoursePage/assets/instructor-img/Abarajithan Gnaneswaran.webp";
import subodhaCharles from "../../components/CoursePage/assets/instructor-img/Subodha Charles.webp";
//import rangaRodrigo from "../../components/CoursePage/assets/instructor-img/Ranga Rodrigo.webp";
import fdsdCoureImage from "../../components/ProgramCatalog/assets/fundamentals-of-digitla-system-design.webp";
//import pahanmendis from "../../components/CoursePage/assets/instructor-img/Pahan Mendis.webp";
//import chathuniw from "../../components/CoursePage/assets/instructor-img/Chathuni Wijegunawardana.webp";
//import pramudithas from "../../components/CoursePage/assets/instructor-img/Pamuditha.webp";
//import mohamedA from "../../components/CoursePage/assets/instructor-img/Afham.webp";
//import sanjana from "../../components/CoursePage/assets/instructor-img/Sanjana.webp";
//import devnith from "../../components/CoursePage/assets/instructor-img/Devnith.webp";
//import damith from "../../components/CoursePage/assets/instructor-img/Damith.webp";
import dineth from "../../components/CoursePage/assets/instructor-img/dineth.jpg";
import supun from "../../components/CoursePage/assets/instructor-img/supun.jpg";
import thuva from "../../components/CoursePage/assets/instructor-img/thuva.jpg";
import jazoolee from "../../components/CoursePage/assets/instructor-img/Jazoolee.jpg";
import uvindu from "../../components/CoursePage/assets/instructor-img/Uvindu.jpg";

import { ECourseStatus, ICourse } from "../utils/utils";
import fdsd_24_flyer from "../../components/CoursePage/assets/fdsd_24.webp";

export const fundamentalsOfDigitalSystemDesign: ICourse = {
  header: {
    courseImage: fdsdCoureImage,
    programCategory: "Short Course",
    difficultyLevel: "Intermediate",
    courseTitle: "Fundamentals of Digital System Design '24",
    specialization: "Build Your Own CPU",
    courseDescription:
      "Explore the essential principles of Digital System Design with Skill Surf’s comprehensive course. This program melds foundational theories with hands-on applications, equipping you to design and optimize digital systems effectively. Delve into circuit design, logic, and system architecture to build robust digital solutions that are crucial for today’s technology-driven landscapes.",
    registerButton: "View Instructions for Next Steps",
    registerLink: "https://docs.google.com/document/d/1SnVWrV_e8SlKnjvCmkWAMgdqZVEoBU8g/edit?usp=sharing&ouid=114248016180290910181&rtpof=true&sd=true",
    secondaryName: "",
  },
  headerSecondSection: {
    starCount: 0,
    reviewCount: 0,
    registrationDeadline: "",
    parameterOne: "Estimated Time",
    parameterTwo: "8 Weeks",
    parameterThree: "",
    collaborationLogos: [
      {
        collaborationLogo: entcImage,
        collaborationLogosName: "ENTC Logo",
      },
     
      {
        collaborationLogo: uomImage,
        collaborationLogosName: "UOM Logo",
      },
    ],
  },
  courseOutline: [
    {
      session: "",
      sessionContent: [
        {
          heading: "Introduction to Digital Design and SystemVerilog",
          description:
            "",
          subContent: [],
        },
        {
          heading: "Combinational logic circuits",
          description:
            "",
          subContent: [],
        },
        {
          heading: "Sequential logic circuits",
          description:
            "",
          subContent: [],
        },
        {
          heading: "Using the ASIC/FPGA tools to synthesize the design",
          description:
            "",
          subContent: [],
        },
        {
          heading: "Finite state machines",
          description:
            "",
          subContent: [],
        },

        {
          heading: "Clock integration",
          description:
            "",
          subContent: [],
        },

        {
          heading: "Optimizations and hierarchical design",
          description:
            "",
          subContent: [],
        },
      ],
    },
  ],
  faqs: [
 
  ],
  experts: [
    {
      image: dineth,
      name: "Mr. Dineth Mudalige",
      designation: "PhD Student, University of Melbourne, Australia",
    },
    {
      image: abarajithanGnaneswaran,
      name: "Mr. Abarajithan Gnaneswaran",
      designation:
        "PhD Student in Electrical and Computer Eng. , UC San Diego, USA",
    },
    {
      image: supun,
      name: "Mr. Supun Kuruppu",
      designation: "Associate Electronics Engineer, Paraqum Technologies, Sri Lanka",
    },
    {
      image: thuva,
      name: "Thuvaragan Sooriyakumaran",
      designation: "3rd Year Undergraduate, ENTC, UoM",
    },
    {
      image: jazoolee,
      name: "Mr. Jazoolee Ahamed",
      designation: "3rd Year Undergraduate, ENTC, UoM",
    },
    {
      image: uvindu,
      name: "Uvindu Kodikara",
      designation: "3rd Year Undergraduate, ENTC, UoM",
    },
  
    {
      image: kithminWickremasinghe,
      name: "Mr. Kithmin Wickremasinghe",
      designation: "MASc Student in Electrical and Computer Eng. , UBC, Canada",
    },
   
    
    {
      image: subodhaCharles,
      name: "Dr. Subodha Charles",
      designation: "Senior Lecturer, ENTC, UoM",
    },
   
  ],
  studentReviews: [
    "On the 1st day, when I saw the registration details, I went through the course curriculum and I joined the course with an expectation. The team fulfilled my expectations 100%.",
    "The course is very interesting and provides a good knowledge in the intended learning area. I would like to see more courses like this in future.",
    "This was a great course. Although the instructors couldn't touch all the aspects, guiding the students on what to follow and refer to is a great thing. Grateful for the organizers and instructors sharing their knowledge. Hope to join the next courses as well.",
    "The personal ML/embedded projects that the instructors shared as case studies were really inspiring. Sharing the ups and downs and what to expect along this path is a real motivator!",
    "Flow of the content was really great. It will really help to beginners who are entering to ML & Edge computing.",
  ],
  facts: [
    {
      fact: "A review by Gartner found that as of 2018, just 10% of all data was processed at the edge. However, Gartner expects that by 2025",
      factBold: "75% of all porcessing with happen at the edge",
      author: "-digi.com-",
    },
    {
      fact: "The global market for Embedded AI is expected to grow at a 5.4% CAGR from 2021 to 2026, reaching about ",
      factBold: "USD 38.87 billion",
      author: "-dac.digital-",
    },
  ],
  classSchedule: [
    "15 September 2024 (Concluded)",
    "28 September 2024: 1 PM - 5 PM",
    "29 September 2024: 9 AM - 1 PM",
    "6 October 2024: 9 AM - 1 PM",
    "13 October 2024: 9 AM - 1 PM",
    "20 October 2024: 9 AM - 1 PM",
    "27 October 2024: 9 AM - 1 PM",
    "2 November 2024: 1 PM - 5 PM",
    "3 November 2024: 9 AM - 1 PM"
  ],
  
  prerequisite:
    "Basic knowledge of programming in any language. Microcontroller programming knowledge will be a plus but not mandatory.",
  
  


  isCourseFlyer: true,
  flyer: {
    description:
      'Learn more about Fundamentals of Digital System short course from the course announcement flyer',
    button: "View Course Flyer",
    image: fdsd_24_flyer,
  },
  courseStatus: ECourseStatus.NEW,
  showTimer: false,
  learnMore: {
    description:
      "Read More About Fundamentals of Digital System",
    button: "Read More",
    articleLink:
      "https://medium.com/@SkillSurfEdu/the-global-shortage-of-chip-design-talent-and-the-importance-of-practical-training-238d053f0f56",
  },
  
        firstSessionFreeDetails: {
    instruction: {
      isInstructive: true,
      instructiveText:
        "The first session, which was free of charge to everyone, was held on 15 September 2024. The recording of the session is made available below.",
      instructiveTextSecond:
        "Only paid participants can attend from day 2 onwards. So, please complete the payment by ",
      date: "",
      dateSecond: "25 September 2024.",
      buttonText: "View Course Outline",
      buttonTextInfo: "View Slides",
      viewIntruction: "View Instructions for Next Steps",
      instructionVideoTopic:"Recording of the first sessions held on 15 September 2024:",
      instructionVideoUrl: "dm-Lvw_XpJA",
      videoTwoTopic:"",
      videoTwoUrl:"",
      viewIntructionLink:
        "https://docs.google.com/document/d/1SnVWrV_e8SlKnjvCmkWAMgdqZVEoBU8g/edit?usp=sharing&ouid=114248016180290910181&rtpof=true&sd=true",
      buttonLink:
        "",
      buttonLinkInfo:
        "https://drive.google.com/file/d/1J_HUfNkKTzMnSudSmCwQAizWEpgwwNyX/view?usp=drive_link",
      moreInformation:
        "For any questions, please send a WhatsApp message to Ms. Shehani Jayasinghe at +94 767 666 555.",
        slideButtonText:"Download Slides:",
        paymentInstructionButtonText:"here", 
        paymentInstructionButtonLink:"https://docs.google.com/document/d/1SnVWrV_e8SlKnjvCmkWAMgdqZVEoBU8g/edit?usp=sharing&ouid=114248016180290910181&rtpof=true&sd=true",
        paymentInstructionText:"Payment and other related instructions can be found"

      
    },
  }, 
    

  meetSection :{
    meetDetails:false,
    introPara: "The introductory session, which is free of charge to everyone, will be held on 15th September (Sunday) 2024 at 10.30 am IST. This first session will give an overview of the course, the content covered, and the expected outcomes. Please use the Zoom link to join the session:",
    zoomLink:"https://learn.zoom.us/j/94267767096?pwd=UG53MnpaYjdyZGpQNnozemVNencwQT09",
    meetId:"942 6776 7096",
    passCode:"205fD32*",
    zoomLinkText:"Click here to join ",
    introSecondPara:"The introductory session and an email following the session will provide further instructions on how to make the payments and enroll in the course. See you on 15th!",
  },
 

  showSchedule:true
};
