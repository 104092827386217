import intro_to_gen_ai_image from "../../components/ProgramCatalog/assets/IntroductiontoGenerativeAI.webp";
import QuatumImage from "../../components/CoursePage/assets/quantum_lab.webp";
import entcImage from "../../components/CoursePage/assets/entc-logo.webp";
import ieeeImage from "../../components/CoursePage/assets/ieee.webp";
import devnith from "../../components/CoursePage/assets/instructor-img/Devnith.webp";
import kanchana from "../../components/CoursePage/assets/instructor-img/Kanchana_Ranasinghe.webp";
import afham from "../../components/CoursePage/assets/instructor-img/Afham.webp";
import Abarajithan from "../../components/CoursePage/assets/instructor-img/Abarajithan Gnaneswaran.webp";
import sadeep from "../../components/CoursePage/assets/instructor-img/Sadeep Jayasumana.webp";
import ranga from "../../components/CoursePage/assets/instructor-img/Ranga Rodrigo.webp";
import genAiFlyer from "../../components/CoursePage/assets/intro_to_gen_ai.webp";
import { ECourseStatus, ICourse } from "../utils/utils";

export const introductionToGenAi: ICourse = {
  header: {
    courseImage: intro_to_gen_ai_image,
    programCategory: "Workshop",
    difficultyLevel: "Beginner",
    courseTitle: "Introduction to Generative AI",
    specialization: "Free Workshop",
    courseDescription:
      'Dive into the transformative world of Generative AI in this one-day session designed to unlock the fundamentals and unleash your creativity. With the rising demand and future potential of Generative AI in the industry, ENTC, together with IEEE Sri Lanka Section and Skill Surf, aims to train engineers in this area. “Introduction to Generative AI” is a one-day workshop open to everyone offered free of charge.',
    registerButton: "Registration Closed",
    registerLink: "https://forms.gle/ZJyAems5FACKCQXQA",
    secondaryName: "One Day Online Workshop",
  },
  headerSecondSection: {
    starCount: 0,
    reviewCount: 0,
    registrationDeadline: "05 June 2024",
    parameterOne: "Workshop Date:",
    parameterTwo: "9 June 2024",
    parameterThree: "New Workshop",
    collaborationLogos: [
    
      {
        collaborationLogo: entcImage,
        collaborationLogosName: "ENTC Logo",
      },
      {
        collaborationLogo: ieeeImage,
        collaborationLogosName: "IEEE Logo",
      },
    ],
  },
  courseOutline: [
    {
      session: "Session 1",
      sessionContent: [
        {
          heading: "Welcome",
          description: "",
          subContent: [],
        },
        {
          heading: "Keynote speech",
          description: "",
          subContent: [],
        },
        {
          heading:
            "Introduction to Transformers and engineering basics",
          description: "",
          subContent: [],
        },
        {
          heading:
            "Hands-on session 1 - Building a GPT from scratch",
          description: "",
          subContent: [],
        },
       
      ],
    },
    {
      session: "Session 2",
      sessionContent: [
        {
          heading: "Introduction to Diffusion models and image generation basics",
          description: "",
          subContent: [],
        },
        {
            heading: "Hands-on session 2 - Fine-tuning a model for your own images",
            description: "",
            subContent: [],
          },

          {
            heading: "Q&A session and Conclusion",
            description: "",
            subContent: [],
          },
      ],
    },
  ],
  faqs: [],
  experts: [
    {
      image: devnith,
      name: "Mr. Devnith Wijesinghe",
      designation: "Workshop Organizer - 3rd Year Undergraduate, ENTC, UoM",
    },
    {
      image: kanchana,
      name: "Mr. Kanchana Ranasinghe",
      designation: "PhD Student in Computer Vision, Stony Brook University, USA",
    },
    {
      image: afham,
      name: "Mr. Mohamed Afham",
      designation:
        "PhD student in Computer Vision, TU Darmstadt, Germany",
    },
    {
      image: Abarajithan,
      name: "Mr. Abarajithan Gnaneswaran",
      designation:
        "PhD Student in Electrical and Computer Eng. , UC San Diego, USA",
    },
    {
        image: sadeep,
        name: "Dr. Sadeep Jayasumana",
        designation:
          "Staff Research Scientist in ML/AI, Google, New York, USA",
      },
      {
        image: ranga,
        name: "Dr. Ranga Rodrigo",
        designation:
          "Former HoD/Senior Lecturer, ENTC, UoM",
      },
  ],
  studentReviews: [
    "On the 1st day, when I saw the registration details, I went through the course curriculum and I joined the course with an expectation. The team fulfilled my expectations 100%.",
    "The course is very interesting and provides a good knowledge in the intended learning area. I would like to see more courses like this in future.",
    "This was a great course. Although the instructors couldn't touch all the aspects, guiding the students on what to follow and refer to is a great thing. Grateful for the organizers and instructors sharing their knowledge. Hope to join the next courses as well.",
    "The personal ML/embedded projects that the instructors shared as case studies were really inspiring. Sharing the ups and downs and what to expect along this path is a real motivator!",
    "Flow of the content was really great. It will really help to beginners who are entering to ML & Edge computing.",
  ],
  facts: [],
  classSchedule: [
    
  ],
  prerequisite: "Basic Python programming",
  isCourseFlyer: true,
  flyer: {
    description:
      "Learn more about Introduction to Generative AI workshop from the workshop announcement flyer",
    button: "View Flyer",
    image: genAiFlyer,
  },
  courseStatus: ECourseStatus.OLD,
  showTimer: false,
  isDifferentBullet: true,
  isResources: true,
  resourcesYoutubeLink:"https://www.youtube.com/@skillsurfedu",
  resourcesGitHubLink:"https://github.com/SkillSurf/introduction_genAI",
  timerDate:"2024-06-06 00:00",
  showSchedule:true
};
